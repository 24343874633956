export default{
  data(){
    return{
      user:{
        email:""
      },
      mode:1,
      load:false
  }
  },
  methods:{
    send(){
      this.$refs.form_pass_lost.validate().then((success) => {
        if (success) {
          this.load = true
          this.axios.post(this.$store.getters.resetPassword,{email:this.user.email})
            .then(() => {
              this.$awn.success(this.$t('success.password_reset_link_sent'))
              this.load = false
              this.$emit('registered')
            })
            .catch(err => {
              this.load = false
              console.log(err)
            })
        }
      })
    }
  }
}