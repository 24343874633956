import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/es5/locale/es'
import en from 'vuetify/es5/locale/en'
import { i18n } from './i18n.js';

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
    iconfont: 'fa',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // primary: '#0a57a5',
        background: '#DDDDDD'
      },
      dark: {
        // primary: '#0a57a5',
      }
    }
  },
  lang: {
    locales: { es, en },
    current: i18n.locale,
  }
});
