import lang from "@/locale/localeTemplate.vue"
import passwordLost from "./PasswordLost.vue"

export default{
  components:{
    language:lang,
    passwordLost:passwordLost
  },
  data(){
    return{
      user:{
        username:"",
        password:"",
      },
      showPass:false,
      mode:1,
      load:false
    }
  },
  methods:{
    login(){
      this.$refs.form_login.validate().then((success) => {
        if (success) {
          this.load = true
          this.axios
          .post(this.$store.getters.postUserLogin, this.user)
          .then((response) => {
            localStorage.setItem('token', response.auth_token)
            this.axios.defaults.headers['Authorization'] = 'Token ' + response.auth_token;
            this.$store.commit("loginToken", response.auth_token)
            this.$store.commit('setMe',response.user)
            this.$refs.form_login.reset()
            const group = response.user.groups.map( group => group.name)
            if(group.includes("Admin") || group.includes("Operator")){
              this.$router.push("/dashboard")
            }            
          })
          .finally(() => {
            this.load = false
          })
          .catch((e) => {
            if(e.status == 400) {
              this.$awn.alert(this.$t('messages.bad_credentials'))
            }
            localStorage.clear()
            this.$store.dispatch('logouToken')
          })
        }
      })
    }
  }
}