export const es_veeValidate = {
  code: "es",
  // Fields Names For Vee-Validate
  names: {
    address: "Dirección",
    amount: "Monto",
    bank: "Banco",
    birthday: "Fecha de Nacimiento",
    cardNumber: "Número de tarjeta",
    category: "Categoría",
    cellphone: "Celular",
    city: "Ciudad",
    code: "Código",
    created_at:"Creado el",
    date: "Fecha",
    description: "Descripción",
    dni: "Cédula",
    username: "Usuario",
    email: "Correo Electrónico",
    features: "Características",
    first_name: "Nombre",
    gender: "Género",
    identification: "ID o RIF",
    image: "Imagen",
    last_name: "Apellido",
    legal_denomination: "Razón social",
    name: "Nombre",
    message: "Mensaje",
    observations: "Observaciones",
    password: "Constraseña",
    password_confirmation: "Confirmar constraseña",
    payment_method: "Método de Pago",
    phone: "Teléfono",
    prefix: "Prefijo",
    price: "Precio",
    priceUnit: "Precio Unitario",
    priceTotal: "Precio Total",
    quantity: "Cantidad",
    search: "buscar",
    state: "Estado",
    type: "Tipo",
    updated_at:"Actualizado el",
    title: "Título",
    value: "Valor",
    fname_lname: "Nombre completo",
    subdomain: "Subdominio",
    subject: "Asunto",
    company: "Empresa",
  },
  // Messages For Vee-Validate
  messages: {
    onlyNumbers: "El campo {_field_} debe tener solo números",
    password_confirmation: "Las contraseñas no coinciden",
    upperLetter: "El campo {_field_} debe tener al menos una letra mayúscula",
    lowerLetter: "El campo {_field_} debe tener al menos una letra minúscula",
    minANumber: "El campo {_field_} debe tener al menos un número",
    specialChar: "El campo {_field_} debe tener al menos un carácter especial: ! @ # $ % ^ & * ( ) - _ + .",
    greaterThanZero:"El campo {_field_} debe ser mayor que cero",
    decimal:"El campo {_field_} debe contener solo valores decimales",
    percent : "El campo ${_field_} es un porcentaje su valor debe estar entre 0 y 100",
  }
};
