<template>
  <v-tooltip top v-if="!searching && !permanent">
    <template v-slot:activator="{ on }">
      <v-btn 
        :color="color" 
        text
        :class='classBtn'
        :small="$vuetify.breakpoint.smAndDown"
        @click="changeMode(true)"
        v-on="on">
        <v-icon>fas fa-search</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('actions.search') }}</span>
  </v-tooltip>
  <v-text-field
    :class="classes"
    v-else
    autofocus
    single-line
    clearable
    v-model="search"
    :label="$t('actions.search')"
    :prepend-icon="permanent ? '' : 'fa-chevron-right'"
    append-outer-icon="fa-search"
    @keydown.esc.prevent="changeMode(false)"
    @keydown.enter.prevent="$emit('searchinfo',search)"
    @click:clear="$emit('searchinfo','')"
    @click:append-outer="$emit('searchinfo',search)"
    @click:prepend="changeMode(false)"
  ></v-text-field>
</template>
<script>
export default {
  props:{
    color:{
      type:String,
      default:'white'
    },
    classes:{
      type:String,
      default:'mt-5'
    },
    classBtn:{
      type:String,
      default:''
    },
    permanent:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      search:"",
      searching: false
    }
  },
  methods:{
    changeMode(val){
      this.searching = val
      this.$emit('searching',val)
      if(!val){
        this.search = ""
        this.$emit('searchinfo',this.search)
      }
    }
  }
}
</script>