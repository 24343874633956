import { es } from './es.js'
import { en } from './en.js'
import en_validation from 'vee-validate/dist/locale/en.json';
import es_validation from 'vee-validate/dist/locale/es.json';

es.validation.messages = Object.assign(
  es.validation.messages,
  es_validation.messages
);
en.validation.messages = Object.assign(
  en.validation.messages,
  en_validation.messages
);

export const translations = {
  es: es,
  en: en
}
