/*
 * API REST URL
 */
export let apiUrl = process.env.VUE_APP_API_URL;

export default {
  getters: {
    // Public / Generic
    get503Status: () => {
      return apiUrl + "ping";
    },

    // User
    getUser: () => {
      return apiUrl + "auth/users/";
    },
    getUserInfo: () => {
      return apiUrl + "auth/users/me/";
    },
    resetPassword: () => {
      return apiUrl + "auth/users/reset_password/";
    },
    forgotSetPassword: () => {
      return apiUrl + "auth/users/reset_password_confirm/";
    },
    postUserLogin: () => {
      return apiUrl + "auth/token/login";
    },
    getUserLogout: () => {
      return apiUrl + "auth/token/logout";
    },

    // -------------------------------------------
    //                   ADMIN
    // -------------------------------------------
    // Chat
    getChats: () => (pk) => {
      return pk != undefined ? apiUrl + "chat/" + pk + "/" : apiUrl + "chat/";
    },
    getMessages: () => (pk) => {
      return pk != undefined
        ? apiUrl + "messages/" + pk + "/"
        : apiUrl + "messages/";
    },
    markMessageRead: () => (pk) => {
      return apiUrl + "messages/" + pk + "/mark_read/";
    },
    // Ticket
    getTicket: () => (pk) => {
      return pk != undefined ? apiUrl + "tickets/" + pk + "/" : apiUrl + "tickets/";
    },
    // Operators
    getOperators: () => (pk) => {
      return pk != undefined ? apiUrl + "operators/" + pk + "/" : apiUrl + "operators/";
    },
    userSetPassword: () => (pk) =>  {
      return `${apiUrl}operators/${pk}/set-password/`
    },
  }
};
