import Vue from "vue";
import {
  extend,
  setInteractionMode,
  configure,
  localize,
  ValidationObserver,
  ValidationProvider
} from "vee-validate";
import { translations } from "../locale/translations.js";
import { required, email, max, min, digits, confirmed } from "vee-validate/dist/rules";
import { i18n } from "./i18n";

// Global Components
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`validation.names.${field}`);
    return i18n.t(`validation.messages.${values._rule_}`, values);
  }
});

setInteractionMode("aggressive");

extend("required", required);

extend("min", min);

extend("max", max);

extend("digits", digits);

extend("email", email);

extend("confirmed", confirmed);

extend("confirmBy", {
  params: ["target"],
  validate(value, { target }) {
    return value == target[0];
  },
  // here it is its name, because we are generating a message
  message: "The {_field_} does not match the {target}"
});

extend("password", {
  validate: value => {
    let regex = new RegExp(
      /^(?=.*[a-z]){3,}(?=.*[A-Z]){2,}(?=.*[0-9]){2,}(?=.*[!@#$%^&*()--__+.]){1,}.{8,}$/
    );
    return regex.test(value);
  },
  message: "The Password does not match"
});
// Confirm Pasword
extend("password_confirmation", {
  params: ["target"],
  validate(value, { target }) {
    return value == target;
  }
});
// Filed with Only Numbers
extend("onlyNumbers", {
  validate: value => {
    let regex = new RegExp(/\d/gi);
    return regex.test(value);
  }
});
// Min. an uppercase letter
extend("upperLetter", {
  validate: value => {
    let regex = new RegExp(/[A-Z]/);
    return regex.test(value);
  }
});
// Min. a lowercase letter
extend("lowerLetter", {
  validate: value => {
    let regex = new RegExp(/[a-z]/);
    return regex.test(value);
  }
});
// Min. a number
extend("minANumber", {
  validate: value => {
    let regex = new RegExp(/[0-9]/);
    return regex.test(value);
  }
});
// Min. a special character
extend("specialChar", {
  validate: value => {
    let regex = new RegExp(/[!@#$%^&*()--__+.]/);
    return regex.test(value);
  }
});
extend("decimal", {
  validate: (value, { decimals = '2', separator = ',' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
})

// greater than zero
extend("greaterThanZero", {
  validate: value => {
    return value > 0;
  },
});

// Percent
extend("percent", {
  validate: value => {
    return value > 0 && value <= 100;
  },
});

Vue.mixin({
  methods: {
    setLocale() {
      let language = "";
      if (i18n.locale === "es") {
        language = "en";
        this.$vuetify.lang.current = "en";
        localize("en", translations.en.validation);
      } else {
        language = "es";
        this.$vuetify.lang.current = "es";
        localize("es", translations.es.validation);
      }
      i18n.locale = language;
      localStorage.setItem("ts-lang", language);
    }
  }
});
