import { en_veeValidate } from './en_veeValidate.js';

export const en = {
  and: 'and',
  of: 'of',
  of2: 'of',
  or: 'or',
  the: 'The|The|the|the',
  the_2: 'The|The|the|the',
  to: 'to|to',
  yes: 'Yes|yes',
  no: 'No|no',
  accepted: 'accepted|accepted',
  approved: 'approved|approved',
  canceled: 'canceled|canceled',
  completed: 'completed|completed',
  created: 'created|created',
  deleted: 'deleted|deleted',
  denied: 'denied|denied',
  disabled: 'disabled|disabled|Disabled|Disabled',
  enabled: 'enabled|enabled',
  generated: 'generado|generada',
  paid: 'pagado|pagada',
  pending: 'pending|pending',
  restored: 'restored|restored',
  saved: 'saved|saved',
  sent: 'sent|sent',
  updated: 'updated|updated',
  contacted: 'contacted|contacted',
  start_date: 'Start Date',
  end_date: 'End Date',
  origin: 'Origin',
  active: 'Active|Active',
  wait: 'Wait',
  too_many_attempts: 'Too many attempts',
  all: 'All|All',
  home_intro: 'Welcome to the Eprint ticket system, here you can report incidents that have occurred in your application or subdomain, press the button to fill out the form and send a report.',
  report: 'Report',
  // Actions
  actions: {
    action: 'Action|Actions',
    create: 'Create',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    disable: 'Disable',
    enable: 'Enable',
    accept: 'Accept',
    save: 'Save',
    cancel: 'Cancel',
    close: 'Close',
    send: 'Send',
    forgot: 'Forgot',
    confirm: 'Confirm',
    continue: 'Continue',
    next: 'Next',
    prev: 'Prev.',
    search: 'Search',
    select: 'Select',
    pay: 'Pay',
    new: 'New|New',
    back: 'Back',
    view: 'View',
    more: 'More|more',
    hide: 'Hide',
    add: 'Add',
    open: 'Open',
    options: 'Options'
  },
  emailverify: {
    emailverify: 'Email Verify',
    unverified: 'In order to activate your account, your email address must be verified.<br><br>An email has been sent to your registered email address. Click on the <b>Verify Email Address</b> button.<br><br>If you can\'t find the message in your Inbox, check your Spam folder, or click here to resend:',
    success: 'Your email has successfully been verified',
    expired: 'The verification URL has expired.<br>{0}|Your email couldn\'t be verified.<br>{0}',
    request_resend: 'Log in to request a new verification message',
    request_resend_logged: 'Click here to request a new verification message:',
    resend: 'Resend',
    already_verified_email: 'The email address has already been verified',
    notification_resubmitted: 'The email verification message has been resubmitted',
    not_verified: 'You must verify your email',
    invalid_signature: 'Invalid email Signature',
  },
  error_network: 'Network Error',
  // Errors on Pages
  ErrorPage: {
    error_401: 'Unauthorized',
    error_403: 'Forbidden',
    error_404: 'Page Not Found',
    error_500: 'Server error',
    error_503: 'Maintenance Mode',
    message_403: 'Forbidden access',
    message_404: 'Something went wrong',
    message_503: '',
    description_403:
      'You do not have the necessary permissions to continue, contact the administrator for more information.',
    description_404: '',
    description_503:
      'The service is temporarily unavailable, sorry for the inconvenience.'
  },
  success: {
    register_user: 'Registration successful. Welcome!',
    contact_me: 'Message sent successfully!',
    password_reset_link_sent: 'A password reset link was sent to your email address'
  },
  validation: {
    code: en_veeValidate.code,
    names: en_veeValidate.names,
    messages: en_veeValidate.messages
  },
  user: {
    email: 'Email',
    first_name: 'First Name',
    forget_your_password: 'Did you forget your password?',
    last_name: 'Last Name',
    login: 'Login',
    logout: 'Logout',
    logout_confirm: 'Are you sure you want to Logout?',
    loginUser: 'Do you already have an account? Log in',
    register: 'Register|Register',
    registerUser: 'Don\'t have an account? Register',
    password: 'Password',
    password_repeat: 'Password Repeat',
    password_update: 'Password Update',
    password_reset: {
      password_recovery: 'Password Recovery',
      password_reset: 'Password Reset',
      reset_link_sent: 'A password reset link was sent to your email address',
      invalid_token: 'This password reset token is invalid',
      token_expired: 'This password reset token has expired',
      email_not_found: 'The email address is not registered',
      re_request: 'You can make a new password recovery request, by clicking here:',
      succeeded: 'Password reset succeeded! You can <b>Login</b> now'
    },
    users: 'Users',
    username: 'Username'
  },
  admin: {
    dashboard: 'Dashboard',
    operator: 'Operator|Operators',
    sections: 'Sections',
    ticket: 'Ticket',
    my_tickets: 'My Tickets',
    exit: 'Exit'
  },
  messages: {
    bad_credentials: 'Bad email or password combination',
    edited_successfully: 'Edited sucessfully',
    created_successfully: 'Created sucessfully',
    send_successfully: 'Sended sucessfully',
    ticket_taken_successfully: 'Ticket taken sucessfully',
    ticket_completed: 'Ticket completed sucessfully',
    ticket_success: 'Report sended sucessfully, you will be notified by mail for enter in the support chat'
  },
  operator: {
   add_operator: 'Add Operator' 
  },
  ticket: {
    name: 'Name',
    fullname: 'Full name',
    subdomain: 'Subdomain',
    status: 'Status',
    ticket_number: 'Ticket Number',
    mark_has_completed: 'Mark has completed',
    mark_has_completed_text: 'Do you want to mark this incident as completed? This action can not be undone.',
    subject: 'Subject',
    company: 'Company',
    date: 'Date',
    status_type: {
      created: 'Created',
      assigned: 'Assigned',
      closed: 'Closed'
    }
  },
  chat: {
    type_here: 'Write a message',
    new_chat: 'New chat',
    recent_chats: 'Recent chats',
    user_panel: 'User Panel',
    message: 'Message',
    guest: 'Guest',
    see_more: 'See More',
  }
};
