import Vue from "vue";
import Router from "vue-router";
import store from "./store";
import Login from './pages/login/Login.vue'

Vue.use(Router);

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuth) {
    next();
    return;
  }
  next("/login");
};

const isAdmin = (to, from, next) => {
  if (store.getters.isAdmin) {
    next();
    return;
  }
  next("/login");
};

const isOperator = (to, from, next) => {
  if (store.getters.isOperator) {
    next();
    return;
  }
  next("/login");
};

const isAdminOrOperator = (to, from, next) => {
  if (store.getters.isOperator || store.getters.isAdmin) {
    next();
    return;
  }
  next("/login");
};

let rout = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import(/* webpackChunkName: "home" */ './pages/home/Home.vue'),
    },
    {
      path: "/chat/:id",
      name: "chat",
      component: () => import(/* webpackChunkName: "chat" */ './pages/chat/Chat.vue'),
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import(/* webpackChunkName: "dashboard" */ './pages/dashboard/Dashboard.vue'),
      beforeEnter: ifAuthenticated && isAdminOrOperator,
    },
    {
      path: "/tickets",
      name: "tickets",
      component: () => import(/* webpackChunkName: "tickets" */ './pages/ticket/TicketList.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/my-tickets",
      name: "my-tickets",
      component: () => import(/* webpackChunkName: "my-tickets" */ './pages/ticket/MyTickets.vue'),
      beforeEnter: ifAuthenticated && isOperator,
    },
    {
      path: "/free-tickets",
      name: "free-tickets",
      component: () => import(/* webpackChunkName: "my-tickets" */ './pages/ticket/OperatorTicket.vue'),
      beforeEnter: ifAuthenticated && isOperator,
    },
    {
      path: "/operators",
      name: "operators",
      component: () => import(/* webpackChunkName: "operators" */ './pages/operator/OperatorList.vue'),
      beforeEnter: ifAuthenticated && isAdmin,
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      //beforeEnter: ifAuthenticated,
    },
    {
      path: "/forgot-password/:uid/:hash",
      name: "forgot-password",
      component: () => import(/* webpackChunkName: "forgot-password" */ './pages/login/Forgot.vue'),
    },
    //{
    //  path: "*",
    //  name: "pageNotFound",
    //  component: () => import(/* webpackChunkName: "pageNotFound" */ './components/General/error/PageNotFound.vue'),
    //},
  ],
});

/*rout.beforeEach(async (to, from, next) => {
  if(localStorage.getItem('subdomain') === null || localStorage.getItem('subdomain') === undefined){
    if(!store.getters.getIsloading){
      store.dispatch("actionLoading");
    }
    await store.dispatch("consultSubdomain", location.hostname);
    if(store.getters.getIsloading){
      store.dispatch("actionLoading");
    }
  }
  
  if (store.getters.getSubdomainFound || to.name == "pageNotFound") {
    next();
    return;
  }
  next({ name: "pageNotFound" });
});

rout.beforeResolve((to, from, next) => {
  if (store.getters.isAuth) {
    if (store.dispatch("verifyToken")) {
      next();
    }
  } else {
    next();
  }
});

rout.beforeEach((to, from, next) => {
  if (
    store.getters.isAuth &&
    to.name != "home" &&
    to.name != "login" &&
    to.name != "pageNotFound"
  ) {
    store.dispatch("actionLoading");
  }
  next();
});*/

export default rout;
