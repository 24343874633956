<template>
<v-btn 
  color="white"
  text
  @click="changeLang"
>
  <v-icon class="ma-0 mr-2">fas fa-globe</v-icon>
  <span v-if="this.$i18n.locale === 'es'">ES</span>
  <span v-else>EN</span>
</v-btn>
</template>

<script>
export default {
  data () {
    return { 
      langs: [
        {value: 'en', text: 'en'},
        {value: 'es', text: 'es'}
      ],
      language: this.$i18n.locale,
      menu:false
    }
  },
  methods: {
    changeLang: function () {
      let nextLang = this.$i18n.locale === 'es' ? 'en' : 'es'
      this.$i18n.locale = nextLang
      this.$vuetify.lang.current = nextLang
      window.axios.defaults.headers['Accept-Language'] = nextLang
      this.$validator.localize(nextLang)
      localStorage.setItem('locale',nextLang)
    }
  }
};
</script>