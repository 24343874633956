import { es_veeValidate } from './es_veeValidate.js';

export const es = {
  and: 'y',
  of: 'de|De',
  of2: 'del',
  or: 'o',
  the: 'La|El|la|el',
  the_2: 'Los|Las|los|las',
  to: 'a|al',
  yes: 'Si|si',
  no: 'No|no',
  accepted: 'aceptado|aceptada',
  approved: 'aprobado|aprobada',
  canceled: 'canceledo|canceleda',
  completed: 'completado|completada',
  created: 'creado|creada',
  deleted: 'eliminado|eliminada',
  denied: 'negado|negada',
  disabled: 'deshabilitado|deshabilitada|Deshabilitado|Deshabilitada',
  enabled: 'habilitado|habilitada',
  generated: 'generado|generada',
  paid: 'pagado|pagada',
  pending: 'pendiente|pendientes',
  restored: 'restaurado|restaurada',
  saved: 'guardados|guardadas',
  sent: 'enviado|enviada',
  updated: 'actualizado|actualizada',
  contacted: 'contactado|contactada',
  start_date: 'Fecha Inicio',
  end_date: 'Fecha Fin',
  origin: 'Origen',
  active: 'Activo|Activa',
  wait: 'Espere',
  too_many_attempts: 'Demasiados intentos',
  all: 'Todo|Todos',
  home_intro: 'Bienvenido al sistema de tickets de Eprint, aquí puedes reportar incidencias ocurridas en tu aplicación o subdominio, pulsa el botón para llenar el formulario y enviar un reporte.',
  report: 'Reporte',
  // Actions
  actions: {
    action: 'Acción|Acciones',
    create: 'Crear',
    edit: 'Editar',
    update: 'Actualizar',
    delete: 'Eliminar',
    disable: 'Deshabilitar',
    enable: 'Habilitar',
    accept: 'Aceptar',
    save: 'Guardar',
    cancel: 'Cancelar',
    close: 'Cerrar',
    send: 'Enviar',
    forgot: 'Recuperar',
    confirm: 'Confirmar',
    continue: 'Continuar',
    next: 'Siguiente',
    prev: 'Anterior',
    search: 'Buscar',
    select: 'Seleccione',
    pay: 'Pagar',
    new: 'Nuevo|Nueva',
    back: 'Atrás',
    view: 'Ver',
    more: 'Más|más',
    hide: 'Ocultar',
    add: 'Agregar',
    open: 'Abrir',
    options: 'Opciones'
  },
  emailverify: {
    emailverify: 'Verificación de correo electrónico',
    unverified: 'Para activar su cuenta, su dirección de correo electrónico debe ser verificada.<br><br>Se ha enviado un correo a su dirección de correo registrada. Haga clic en el botón <b>Verificar Correo</b>.<br><br>Si no puede encontrar el mensaje en su Bandeja de Entrada, verifique su carpeta de Spam, o haga clic aquí para reenviar:',
    success: 'Su correo ha sido verificado exitosamente',
    expired: 'La URL de verificación ha caducado.<br>{0}|Su dirección de correo electrónico no pudo ser verificada.<br>{0}',
    request_resend: 'Ingresa para solicitar un nuevo mensaje de verificación',
    request_resend_logged: 'Haga clic aquí para solicitar un nuevo mensaje de verificación:',
    resend: 'Reenviar',
    already_verified_email: 'La dirección de correo electrónico ya ha sido verificada',
    notification_resubmitted: 'El mensaje de verificación de correo ha sido reenviado',
    not_verified: 'Debe verificar su correo electrónico',
    invalid_signature: 'Firma de correo electrónico Invalida',
  },
  error_network: 'Error de Red',
  // Errors on Pages
  ErrorPage: {
    error_401: 'No autorizado',
    error_403: 'No tienes permisos',
    error_404: 'Página No Encontrada',
    error_500: 'Error del Servidor',
    error_503: 'Modo Mantenimiento',
    message_403: 'Acceso prohibido',
    message_404: 'Algo salió mal',
    message_503: '',
    description_403:
      'No tienes los permisos necesarios para continuar, comunícate con el administrador para mayor información.',
    description_404: '',
    description_503:
      'Temporalmente el servicio no esta disponible, disculpe las molestias.'
  },
  success: {
    register_user: '¡Registro exitoso. Bienvenido!',
    contact_me: 'Mensaje enviado con exito!',
    password_reset_link_sent: 'Enlace de restablecimiento de contraseña enviado'
  },
  validation: {
    code: es_veeValidate.code,
    names: es_veeValidate.names,
    messages: es_veeValidate.messages
  },
  user: {
    email: 'Correo',
    first_name: 'Nombre',
    forget_your_password: '¿Olvidaste tu contraseña?',
    last_name: 'Apellido',
    login: 'Ingresar|Iniciar sesión',
    loginUser: '¿Ya tienes cuenta? Iniciar sesión',
    logout: 'Cerrar Sesión',
    logout_confirm: '¿Está seguro de Cerrar Sesión?',
    register: 'Registro|Registrar',
    registerUser: '¿No tienes cuenta? Registrate',
    password: 'Contraseña',
    password_repeat: 'Repita Contraseña',
    password_update: 'Actualizar Contraseña',
    password_reset: {
      password_recovery: 'Recuperar Contraseña',
      password_reset: 'Reestablecer Contraseña',
      reset_link_sent: 'Enlace de restablecimiento de contraseña enviado',
      invalid_token: 'El token de restablecimiento de contraseña es inválido',
      token_expired: 'El token de restablecimiento de contraseña ha caducado',
      email_not_found: 'El correo electrónico no está registrado',
      re_request: 'Puede volver a solicitar la recuperación de su contraseña, haciendo clic aquí:',
      succeeded: 'Contraseña restablecida! Ahora puede iniciar sesión'
    },
    users: 'Usuarios',
    username: 'Nombre de Usuario'
  },
  admin: {
    dashboard: 'Tablero',
    operator: 'Operador|Operadores',
    sections: 'Secciones',
    ticket: 'Ticket',
    my_tickets: 'Mis Tickets',
    exit: 'Salir'
  },
  messages: {
    bad_credentials: 'Combinación incorrecta de correo o contraseña',
    edited_successfully: 'Se editó con éxito',
    created_successfully: 'Se creó con éxito',
    send_successfully: 'Se envió con éxito',
    ticket_taken_successfully: 'Ticket tomado con éxito',
    ticket_completed: 'Se completó el ticket con éxito',
    ticket_success: 'Se envió el reporte con éxito, se le notificará por correo el ingreso al chat de soporte'
  },
  operator: {
   add_operator: 'Agregar Operador' 
  },
  ticket: {
    name: 'Nombre',
    fullname: 'Nombre completo',
    subdomain: 'Subdomino',
    status: 'Estatus',
    ticket_number: 'Número de Ticket',
    mark_has_completed: 'Marcar como completado',
    mark_has_completed_text: '¿Deseas marcar esta incidencia como completada? Está acción no se puede deshacer.',
    subject: 'Asunto',
    company: 'Empresa',
    date: 'Fecha',
    status_type: {
      created: 'Creado',
      assigned: 'Asignado',
      closed: 'Cerrado'
    }
  },
  chat: {
    type_here: 'Escribe un mensaje',
    new_chat: 'Nuevo chat',
    recent_chats: 'Chats recientes',
    user_panel: 'Panel de Usuario',
    message: 'Mensaje',
    guest: 'Invitado',
    see_more: 'Ver Más',
  }
};
