<template>
  <v-menu
    bottom
    left
    offset-y
    class="mr-2"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>fa fa-user</v-icon>
      </v-btn>
    </template>

    <v-list dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary" v-text="'fa-user-alt'"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$store.getters.me.username"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon color="primary" v-text="'fa-sign-out-alt'"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="primary--text" v-text="$t('user.logout')"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  data() {
    return {
      load: false,
      loading: false
    }
  },
  methods: {
    logout() {
      localStorage.clear()
      this.$store.dispatch('logouToken')
      this.$router.push("/")
    }
  }
}
</script>