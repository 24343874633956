export default {
    state: {
        token: localStorage.getItem("token") || null,
        me: JSON.parse(localStorage.getItem("me")) || null
    },
    getters: {
        isAuth: (state) => !!state.token,
        isAdmin: (state) => state?.me?.groups.map( group => group.name).includes("Admin") || false,
        isOperator: (state) => state?.me?.groups.map( group => group.name).includes("Operator") || false,
        me: (state) => {
            return state.me
        }
    },
    mutations: {
        loginToken: (state, token) => {
            state.token = token
        },
        logouToken: (state) => {
            state.token = null
            state.me = null
        },
        setMe(state, me) {
            localStorage.setItem('me', JSON.stringify(me))
            state.me = me
        }
    },
    actions: {
        verifyToken: ({ dispatch }) => {
            if (localStorage.getItem('token')) {
                let fragment = JSON.parse(atob(localStorage.getItem('token').split(".")[1]))
                if (new Date().getTime() > (fragment.exp * 1000)) {
                    var timeDiff = Math.abs(new Date().getTime() - (fragment.exp * 1000))
                    var diffDays = Math.floor(timeDiff / 86400000)
                    if (diffDays >= 2) {
                        dispatch("logouToken")
                        return false
                    }
                    else if (diffDays < 2) {
                        dispatch("updateToken")
                        return true
                    }
                }
                dispatch("checkToken", fragment.exp)
                return true
            }
            else {
                dispatch("logouToken")
            }

        },
        checkToken: ({ dispatch }, exp_date) => {
            var timeDiff = Math.abs((exp_date * 1000) - new Date().getTime())
            var diffDays = Math.floor(timeDiff / 86400000);
            var diffHrs = Math.floor((timeDiff % 86400000) / 3600000);
            var diffMin = Math.round(((timeDiff % 86400000) % 3600000) / 60000);
            if (diffDays == 0 && diffHrs == 0 && diffMin < 10) {
                dispatch("updateToken")
            }
        },
        updateToken: ({ commit, getters, dispatch }) => {
            this.axios
                .post(getters.getRefreshToken, { 'token': localStorage.getItem('token') })
                .then((response) => {
                    //let fragment = JSON.parse(atob(response.data.token.split(".")[1]))
                    localStorage.setItem('token', response.data.token)
                    window.axios.defaults.headers['Authorization'] = 'Token ' +
                        localStorage.getItem('token');
                    commit("loginToken", response.data.token)
                })
                .catch(() => {
                    localStorage.removeItem('token')
                    dispatch("logouToken")
                })
        },
        logouToken: ({ commit }) => {
            commit("logouToken")
        }
    }
}