import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import store from '../store'
window.axios = axios

Vue.use(VueAxios, axios)

if (localStorage.getItem('token')) {
  window.axios.defaults.headers['Authorization'] = 'Token ' + localStorage.getItem('token')
}

// Interceptors REQUEST
window.axios.interceptors.request.use(
  config => HandlerRequest(config),
  error => HandlerErrors(error)
)
// Interceptors RESPONSE
window.axios.interceptors.response.use(
  response => HandlerResponse(response),
  error => HandlerErrors(error)
)

const HandlerRequest = config => {
  if (localStorage.getItem('token')) {
    config.headers.Authorization = 'Token ' + localStorage.getItem('token')
  }
  return config
}

const HandlerResponse = response => {
  let res = response
  // console.log('handler response', response)
  if (response.status == 200) {
    res = { status: 200, ...response.data }
  }
  return res
}

const HandlerErrors = error => {
  let err = error
  if (err.response) {
    err = error.response
    if (err.status) {
      switch (err.status) {
        case 401: {
          err = error.response
          break
        }
        case 403: {
          err = error.response
          break
        }
        case 404: {
          err = error.response
          break
        }
        case 409: {
          err = error.response
          break
        }
        case 422: {
          err = error.response
          break
        }
        case 423: {
          err = error.response
          break
        }
        case 500: {
          err = error.response
          break
        }
        case 503: {
          err = error.response
          /*if (!store.getters.is503) {
            store.dispatch('change503', true)
          }*/
          break
        }
        default: {
          err = error.response
          break
        }
      }
    } else {
      err = 'network_error'
    }
  }

  return Promise.reject(err)
}
