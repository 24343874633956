<template>
  <v-app>
    <v-app-bar
      app
      dark
      dense
      color="primary"
      class="main-bar"
    >
      <div class="d-flex align-center title">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo.png')"
          transition="scale-transition"
          width="40"
        />

        {{ app_name }}
      </div>

      <v-spacer></v-spacer>

      <v-tooltip bottom v-if="!$store.getters.isAuth">
        <template v-slot:activator="{ on }">
          <v-btn outlined
            v-on="on"
            to="/login"
          >
            <span class="mr-2" v-if="!$vuetify.breakpoint.xsOnly">
              {{ $tc('user.login',1) }}
            </span>
            <v-icon size="16">fas fa-user</v-icon>
          </v-btn>
        </template>
        <span>{{ $tc('user.login',1) }}</span>
      </v-tooltip>
      
      <!-- Lang Button -->
      <v-toolbar-items>
        <v-btn text
          :ripple="false"
          class="ml-3 px-0"
          @click="setLocale"
        >
          <span class="mr-1 subtitle-1">
            <span v-if="this.$i18n.locale === 'es'">ES</span>
            <span v-else>EN</span>
          </span>
          <v-icon size="18">fas fa-globe</v-icon>
        </v-btn>
      </v-toolbar-items>

      <v-toolbar-items>
        <userInfo v-if="$store.getters.isAuth" />
      </v-toolbar-items>

    </v-app-bar>

    <v-main class="fill-height">
      <router-view />
    </v-main>
  </v-app>
</template>

<style lang="scss">
  .main-bar {
    .v-toolbar__content {
      padding-right: 0;
    }
  }
</style>

<script>
import UserInfo from './components/operator/UserInfo'

export default {
  name: 'App',
  components: {
    UserInfo
  },

  data: () => ({
    app_name: process.env.VUE_APP_NAME
  })
};
</script>
