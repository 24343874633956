import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {translations} from '@/locale/translations.js'

Vue.use(VueI18n)

let browser_lang = navigator.language.split('-')[0]//process.env.VUE_APP_LOCALE
let lang = localStorage.getItem('ts-lang') != undefined ?
  localStorage.getItem('ts-lang') :
  browser_lang

// Safety verification, in case of localStorage manipulation
if (!Object.prototype.hasOwnProperty.call(translations, lang)) {
  lang = process.env.VUE_APP_LOCALE
}

export const i18n = new VueI18n({
  locale: lang, // set locale
  messages: translations, // set locale messages
  silentTranslationWarn: true, // hide translation fail warnings
})

// Moment JS for date formatting
const moment = require('moment')
if (lang == 'es') {
  require('moment/locale/es')
}
Vue.use(require('vue-moment'), {
  moment
})

// Mixins
export let globals = {
  methods: {
    //
  }
}

Vue.mixin(globals)
