import Vue from 'vue'
import Vuex from 'vuex'
import routers from './plugins/backroutes.js'
import user from './plugins/user.js'
import chat from './plugins/chat.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    route: routers,
    user:user,
    chat:chat
  },
  state: {

  },
  mutations: {

  },
  getters: {

  },
  actions: {

  }
})