export const en_veeValidate = {
  code: "en",
  // Fields Names For Vee-Validate
  names: {
    address: "Address",
    amount: "Amount",
    bank: "Bank",
    birthday: "Birthday",
    cardNumber: "Card number",
    category: "Category",
    cellphone: "Cellphone",
    city: "City",
    code: "Code",
    created_at: "Created at",
    date: "Date",
    description: "Description",
    dni: "DNI",
    username: "Username",
    email: "Email",
    features: "Features",
    first_name: "First Name",
    gender: "Gender",
    identification: "ID",
    image: "Image",
    last_name: "Last Name",
    legal_denomination: "Legal denomination",
    name: "Name",
    message: "Message",
    observations: "Observations",
    password: "Password",
    password_confirmation: "Confirm password",
    payment_method: "Payment method",
    phone: "Phone",
    prefix: "Prefix",
    price: "Price",
    priceUnit: "Unit price",
    priceTotal: "Total price",
    quantity: "Quantity",
    search: "search",
    state: "State",
    type: "Type",
    updated_at: "Updated at",
    title: "Title",
    value: "Value",
    fname_lname: "Full name",
    subdomain: "Subdomain",
    subject: "Subject",
    company: "Company",
  },
  // Messages For Vee-Validate
  messages: {
    onlyNumbers: "The {_field_} field must have only numbers",
    password_confirmation: "Passwords do not match",
    upperLetter: "The {_field_} field must have at least one capital letter",
    lowerLetter: "The {_field_} field must have at least one lowercase letter",
    minANumber: "The {_field_} field must have at least one number",
    specialChar: "The {_field_} field must have at least one special character: ! @ # $ % ^ & * ( ) - _ + .",
    greaterThanZero: "The field {_field_} must be greater than zero",
    decimal: "The {_field_} field must contain only decimal values",
    percent : "The field ${_field_} is a percentage, its value must be between 0 and 100",
  }
};
