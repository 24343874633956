import App from './App.vue'
import router from './router'
import Vue from 'vue'
import VueAWN from 'vue-awesome-notifications'
import vuetify from './plugins/vuetify'
import VueNativeSock from "vue-native-websocket";
import { i18n } from './plugins/i18n.js'
import store from './store';
import './plugins/axiosConfig.js'
import './plugins/veeValidate.js'
import searchfield from "@/components/search/searchField.vue"
import 'vue-awesome-notifications/dist/styles/style.css'


let options = {
  icons: {
    enabled: false
  },
  labels: {
    info: '',
    success: '',
    warning: '',
    alert: '',
    tip: ''
  },
  animationDuration: 400,
  position: 'top-right'
}

Vue.use(VueAWN, options)

Vue.use(require('vue-moment'))

Vue.use(
  VueNativeSock,
  process.env.NODE_ENV === "production"
    ? `${process.env.VUE_APP_SERVER_WEBSOCKET_TYPE}${location.hostname}${process.env.VUE_APP_SERVER_WEBSOCKET_PORT}${process.env.VUE_APP_SERVER_WEBSOCKET_ROUTE}`
    : process.env.VUE_APP_WEBSOCKET,
  { store: store, format: "json" }
);

Vue.component("searchfield", searchfield)

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
